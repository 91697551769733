/** @format */

import React, {
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import {
	Box,
	CircularProgress,
	Fade,
	IconButton,
	Modal,
	useTheme,
} from "@mui/material";
import { TextCus } from "components/TextCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import useClickOutside from "hooks/useClickOutside";
import { fit, Icons, kc2 } from "assets";
import { IDataBodyShape } from "types/outfit";
import { renderStatusBodyShape } from "utils/helpers/data_helpers";
import {
	useDownloadImage,
	useGenMeStore,
	// useGenMeStore,
	useOutfitStore,
	useUser,
} from "hooks";
import { APP_ROOT } from "utils/routes";
import { useNavigate } from "react-router-dom";
import { ThemeOption, TypeServiceName } from "types";

interface IModalDetailBMI {
	open: boolean;
	onClose: () => void;
	dataResult: IDataBodyShape;
}

const ModalDetailBMI = ({ open, onClose, dataResult }: IModalDetailBMI) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const [isOpen, setIsOpen] = useState(false);
	const { user, mode } = useUser();
	const navigate = useNavigate();
	const [previewUrl, setPreviewUrl] = useState<string | null>();
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);
	const { handleDownLoadImg, isLoadingDlImg } = useDownloadImage();
	const theme = useTheme() as ThemeOption;

	// ========== Download img ============

	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	const {
		setSelectedFileModel: setSelectedFileModelGenMe,
		setModelImage: setModelImageGenMe,
		setSelectedFileGallery: setSelectedFileGalleryGenMe,
		setCurrentStep: setCurrentStepGenMe,
	} = useGenMeStore();

	useEffect(() => {
		if (dataResult?.image) {
			typeof dataResult?.image === "string" && setPreviewUrl(dataResult?.image);
		}
	}, [dataResult?.image]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}

		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleChooseModel = useCallback(() => {
		setSelectedFileModel(dataResult.id as string);
		setModelImage(dataResult.image as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
		onClose();
	}, [dataResult]);

	const navigateGenMe = useCallback(() => {
		setSelectedFileModelGenMe(dataResult.id as string);
		setModelImageGenMe(dataResult.image as string);
		setSelectedFileGalleryGenMe(null);
		setCurrentStepGenMe(1);
		navigate(APP_ROOT.GEN_ME);
		onClose();
	}, [dataResult]);

	const arrBtn = [
		{
			title: "Style Fit",
			icon: Icons.Fit(),
			action: () => handleChooseModel(),
			desc: "Change clothes",
		},
		// {
		// 	title: "Style Hair",
		// 	icon: Icons.Hair(),
		// 	action: () => navigateStyleHair(),
		// 	desc: "Change hairstyle",
		// },
		{
			title: "Gen Me",
			icon: Icons.Me(),
			action: () => navigateGenMe(),
			desc: "Head Swap",
		},
	];

	const arrBMI = useMemo(
		() => [
			{
				title: "Under weight",
				desc: "Less than 18.5",
				bgcolor: "#5ADBFC",
				bgActive:
					renderStatusBodyShape(dataResult?.bmi)?.title === "Under weight"
						? "rgba(90, 219, 252, 0.15)"
						: "transparent",
			},
			{
				title: "Normal weight",
				desc: "18.5 - 25",
				bgcolor: "#57EF66",
				bgActive:
					renderStatusBodyShape(dataResult?.bmi)?.title === "Normal weight"
						? "rgba(87, 239, 102, 0.15)"
						: "transparent",
			},
			{
				title: "Over weight",
				desc: "25 - 30",
				bgcolor: "#EFBC06",
				bgActive:
					renderStatusBodyShape(dataResult?.bmi)?.title === "Over weight"
						? "rgba(239, 188, 6, 0.15)"
						: "transparent",
			},
			{
				title: "Obesity",
				desc: "Greater than 30",
				bgcolor: "#CA382B",
				bgActive:
					renderStatusBodyShape(dataResult?.bmi)?.title === "Obesity"
						? "rgba(202, 56, 43, 0.15)"
						: "transparent",
			},
		],
		[dataResult?.bmi],
	);

	const renderButton = useCallback(() => {
		return (
			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"15px"}
				justifyContent={"space-between"}>
				{arrBtn.map((item, index) => (
					<Box
						onClick={item.action}
						sx={{
							cursor: "pointer",
							transition: "all 0.25s ease-in-out",
							background: mainColor.bgLinear,
							"&:hover": {
								".box_desc": {
									top: "-30px",
									opacity: 1,
								},
							},
						}}
						key={item.title}
						borderRadius={"12px"}
						flex={1}
						display={"flex"}
						alignItems={"center"}
						gap={"10px"}
						justifyContent={"center"}
						height={"44px"}
						position={"relative"}>
						{index === 0 ? <img src={fit} alt="fit" /> : item.icon}
						<TextCus
							color={"#ffffff"}
							fontWeight={"600"}
							fontSize={fontSize.xs}>
							{item.title}
						</TextCus>
						<Box
							className="box_desc"
							position={"absolute"}
							height={"20px"}
							bgcolor={"#5553BA"}
							width={"110px"}
							borderRadius={"12px"}
							color={"white"}
							fontSize={"10px"}
							fontWeight={"400"}
							textAlign={"center"}
							lineHeight={"20px"}
							sx={{ transition: "all 0.25s ease-in-out", opacity: 0 }}
							top={"-10px"}>
							{item.desc}
						</Box>
					</Box>
				))}
			</Box>
		);
	}, []);

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={theme.colorSchemes[mode]?.bgModal}
					flexDirection={"column"}
					sx={{
						backdropFilter: "blur(15px)",
						maxWidth: "680px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"905px"}
					overflow={"hidden"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"10px 20px"}
						bgcolor={mode === "dark" ? "rgba(112, 109, 218, 0.7)" : "#E8E7FE"}
						justifyContent={"space-between"}>
						<Box />
						<Box
							display={"flex"}
							alignItems={"center"}
							justifyContent={"center"}
							gap={"10px"}>
							<TextCus
								fontWeight={"700"}
								fontSize={fontSize.sm}
								color={theme.colorSchemes[mode]?.textMain}>
								Your BMI:
							</TextCus>
							<Box color={"#FE55F4"} fontWeight={"700"} fontSize={fontSize.sm}>
								{dataResult?.bmi}
							</Box>
						</Box>
						<IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
							<CloseOutlined
								sx={{ color: theme.colorSchemes[mode]?.textMain }}
							/>
						</IconButton>
					</Box>
					<Box
						padding={"20px"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"space-between"}
						gap={"20px"}
						sx={{ overflowY: "scroll" }}
						width={"100%"}
						flex={1}>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							flex={1}
							width={"100%"}
							gap={"15px"}
							justifyContent={"center"}>
							<Box
								height={"400px"}
								width={"256px"}
								overflow={"hidden"}
								display={"flex"}
								alignContent={"center"}
								justifyContent={"flex-start"}
								flexDirection={"column"}>
								<Box
									height={"400px"}
									overflow={"hidden"}
									display={"flex"}
									alignContent={"center"}
									justifyContent={"flex-start"}
									flexDirection={"column"}>
									{arrBMI.map((item, index) => {
										return (
											<Box
												key={item.bgcolor}
												display={"flex"}
												flex={1}
												alignItems={"center"}>
												<Box
													height={"100%"}
													bgcolor={item.bgcolor}
													width={"16px"}
													borderRadius={
														index === 0
															? "12px 12px 0 0"
															: index === 3
																? "0 0 12px 12px "
																: "0"
													}
												/>
												<Box
													bgcolor={item.bgActive}
													marginLeft={"-10px"}
													sx={{ transition: "all 0.35s ease-in-out" }}
													borderRadius={"0 12px 12px 0"}
													paddingLeft={"25px"}
													width={"100%"}
													height={"100%"}
													display={"flex"}
													flexDirection={"column"}
													justifyContent={"center"}>
													<TextCus
														color={theme.colorSchemes[mode]?.textMain}
														fontSize={fontSize.xs}
														fontWeight={"600"}>
														{item.title}
													</TextCus>
													<TextCus
														color={theme.colorSchemes[mode]?.textMain}
														fontSize={"12px"}
														fontWeight={"400"}>
														{item.desc}
													</TextCus>
												</Box>
											</Box>
										);
									})}
								</Box>
							</Box>
							<Box
								bgcolor={"#D0D2D1"}
								position={"relative"}
								borderRadius={"12px"}
								height={"100%"}
								width={"70%"}>
								<img
									src={previewUrl + ""}
									alt="Preview"
									style={{
										display: "block",
										height: "100%",
										maxWidth: "100%",
										opacity: isPreviewVisible ? 1 : 0,
										transition: "opacity 0.5s ease-in-out",
										borderRadius: "10px",
										objectFit: "cover",
									}}
								/>
								{user?.plan !== "FREE" ? (
									<Box
										onClick={() => {
											handleDownLoadImg(
												previewUrl,
												"Body-shape.png",
												dataResult.id,
												TypeServiceName.BODY_SHAPE,
											);
										}}
										position={"absolute"}
										top={"10px"}
										right={"10px"}
										bgcolor={mainColor.white}
										height={"36px"}
										width={"36px"}
										borderRadius={"10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										sx={{
											cursor: "pointer",
										}}>
										{isLoadingDlImg ? (
											<CircularProgress
												sx={{
													height: "30px !important",
													width: "30px !important",
												}}
											/>
										) : (
											<Icons.Download />
										)}
									</Box>
								) : (
									<Box
										ref={dropdownRef}
										position={"absolute"}
										top={"10px"}
										right={"10px"}
										onClick={() => setIsOpen(true)}
										bgcolor={mainColor.white}
										height={"36px"}
										width={"36px"}
										borderRadius={"10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										sx={{
											cursor: "pointer",
										}}>
										<Icons.Download />
										{/* Dropdown download */}
										<Box
											bgcolor={mainColor.white}
											borderRadius={"12px"}
											position={"absolute"}
											top={isOpen ? "43px" : "20px"}
											sx={{
												opacity: isOpen ? 1 : 0,
												transition: "all 0.25s ease-in-out",
											}}
											width={"249px"}
											padding={"10px"}
											right={0}>
											<Box
												onClick={() => {
													handleDownLoadImg(
														previewUrl,
														"Body-shape.png",
														dataResult.id,
														TypeServiceName.BODY_SHAPE,
													);
												}}
												display={"flex"}
												padding={"10px 0"}
												border={"1px solid #B2B2B2"}
												borderRadius={"10px"}
												sx={{ cursor: "pointer", textDecoration: "none" }}
												gap={"3px"}
												flexDirection={"column"}
												alignItems={"center"}
												justifyContent={"center"}>
												<TextCus
													fontSize={fontSize.xs}
													fontWeight={"600"}
													color={mainColor.textMain}>
													Free download
												</TextCus>
												<TextCus
													fontSize={"12px"}
													fontWeight={"400"}
													color={mainColor.textGray}>
													Included the watermark
												</TextCus>
											</Box>
											<Box
												mt={"10px"}
												display={"flex"}
												padding={"10px 0"}
												sx={{ cursor: "pointer" }}
												borderRadius={"10px"}
												component={"div"}
												onClick={() => {
													navigate(APP_ROOT.PLAN);
													onClose();
												}}
												flexDirection={"column"}
												gap={"3px"}
												bgcolor={"#FFCB00"}
												alignItems={"center"}
												justifyContent={"center"}>
												<Box display={"flex"} alignItems={"center"} gap={"5px"}>
													<img width={20} src={kc2} alt="kc" />
													<TextCus
														fontSize={fontSize.xs}
														fontWeight={"600"}
														color={mainColor.textMain}>
														Premium download
													</TextCus>
												</Box>
												<TextCus
													fontSize={"12px"}
													fontWeight={"400"}
													color={mainColor.textGray}>
													Without the watermark
												</TextCus>
											</Box>
										</Box>
										{/* Dropdown download */}
									</Box>
								)}
							</Box>
						</Box>

						<Box
							display={"flex"}
							height={"135px"}
							flexDirection={"column"}
							justifyContent={"space-between"}
							borderRadius={"12px"}
							width={"100%"}
							gap={"5px"}
							padding={"15px"}
							border={"1px solid #EEEEEE"}>
							<Box display={"flex"} alignItems={"center"} gap={"10px"}>
								<TextCus
									color={theme.colorSchemes[mode]?.textMain}
									fontSize={fontSize.xs}
									fontWeight={"600"}>
									Apply your body shape for:
								</TextCus>
							</Box>
							<TextCus
								color={theme.colorSchemes[mode]?.textMain}
								fontSize={"12px"}
								fontWeight={"400"}>
								Use your body shape with different features of StyleGen now.
							</TextCus>
							{renderButton()}
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalDetailBMI;
