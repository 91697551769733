/** @format */

import {
	do1,
	do2,
	eItem_1,
	eItem_2,
	enFlag,
	Icons,
	koFlag,
	not1,
	not2,
	not3,
	notAnimate,
	notHair_2,
	notHair_3,
	notHands,
	notItem_1,
	notItem_2,
	notItem_3,
	shouldHair_2,
	viFlag,
} from "assets";
import { EPricingPlan } from "types/payment";
import { APP_ROOT } from "./routes";
import { TypeServiceName } from "types";
import moment from "moment";

export const drawerWidth = 240;

export const dimension = {
	width: window.innerWidth,
	height: window.innerHeight,
};

export const KEY_CONTEXT = {
	AUTH_TOKEN: "authTokenStgProd",
	REFRESH_TOKEN: "refreshTokenStgProd",
	LANGUAGE: "language",
	THEME_MODE: "themeMode",
	USER: "user",
	SC_CONNECTION_TOKEN: "connectionToken",
	USER_ID: "userId",
	MODAL_REQUIRE: "modalRequire",
	DEFAULT_URL: "defaultUrl",
	SUGGEST_BODY: "suggestBody",
	BODY_SHAPE: "BODY_SHAPE",
	HD_CLICK: "hd_click",
	HD_SAM: "hd_sam",
};

export const EmpLangSeclect = [
	{
		key: "EN",
		title: "English",
		value: "EN",
		useI18n: true,
	},
	{
		key: "VN",
		title: "Vietnamese",
		value: "VN",
		useI18n: true,
	},
];

export const defaultPagination = {
	total: 0,
	page: 0,
	pageSize: 25,
};

export enum EnumActionApproval {
	comment = "comment",
	approve = "approve",
	return = "return",
	reject = "reject",
	publish = "publish",
	archive = "archive",
	invite = "invite",
	delegate = "delegate",
	attach = "attach",
	print = "print",
	docversion = "docversion",
	history = "history",
	showmore = "showmore",
	lookup = "lookup",
}

export enum EnumBtnBottomModal {
	cancel = "cancel",
	approve = "approve",
	return = "return",
	reject = "reject",
	send = "send",
	save = "save",
	save_and_new = "save_and_new",
	ok = "ok",
	close = "close",
	/* Advance Search  */
	reset = "reset",
	save_as_profile = "save_as_profile",
	search = "search",
	downloadtemp = "downloadtemp",
	add = "add",
	update = "update",
	insert_update = "insert_update",
}

export enum EnumInputVariant {
	base = "base",
	browser = "browser",
	number = "number",
	select = "select",
}

export enum EnumValueDataType {
	String = "String",
	Number = "Number",
	Boolean = "Boolean",
	Object = "Object",
	Array = "Array",
	Null = "Null",
	Undefined = "Undefined",
	Function = "Function",
}

export enum EnumRangeApply {
	A = "A",
	S = "S",
}

export enum EnumYesNoBPMN {
	Y = "Yes",
	N = "No",
}

export enum EnumYesNo {
	Y = "Y",
	N = "N",
}

export enum EnumActionFilterBars {
	import = "import",
	export = "export",
	refresh = "refresh",
}

export const HEADER = {
	H_MOBILE: 64,
	H_DESKTOP: 80,
	H_DESKTOP_OFFSET: 80 - 16,
};

export const NAV = {
	WIDTH: 280,
};
export const EXPECT_LANG = ["ko", "en"];

export const QueryKeys = {
	PROFILE: "profile",
	MODEL_EXAMPLES: "models_examples",
	CLOTHES_EXAMPLES: "clothes_examples",
	MODELS: "models",
	GALLERY_LIST: "galleryList",
	GET_CLOTHES: "getClothes",
	GET_PLAN: "getPlan",
	GET_DETAIL_PLAN: "c",
	MODELS_RANDOM: "MODELS_RANDOM",
	YOUR_MODELS: "YOUR_MODELS",
	YOUR_CLOTHES: "YOUR_CLOTHES",
	GET_DETAIL_GALLERY: "GET_DETAIL_GALLERY",
	TRANSACTION: "TRANSACTION",
	GET_CATEGORIES: "GET_CATEGORIES",
	YOUR_GEN_ME: "YOUR_GEN_ME",
	YOUR_HAIR: "YOUR_HAIR",
	PRESET_MODEL: "PRESET_MODEL",
	TRACKING_PROCESS: "TRACKING_PROCESS",
	MAINTAIN: "MAINTAIN",
	GET_LIST_CATEGORY: "GET_LIST_CATEGORY",
	GET_CLOTHES_BY_TYPE: "GET_CLOTHES_BY_TYPE",
	GET_CLOTHES_BY_PARAMS: "GET_CLOTHES_BY_PARAMS",
};

export const initialOptionsPaypal = {
	clientId: `${process.env.REACT_APP_PAYPAL_CLIENT_ID}`,
	currency: "USD",
	intent: "capture",
};

export const arrTab = [
	{ title: "Upload Photo", value: "UPLOAD" },
	{ title: "Use Model", value: "MODEL" },
];

export const arrTabItems = [
	{ title: "Upload Photo", value: "UPLOAD" },
	{ title: "Use Items", value: "ITEMS" },
];

export const arrGender = [
	{ name: "Male", value: "MALE" },
	{ name: "Female", value: "FEMALE" },
];

export const arrTypeBody = [
	{ name: "Body upload", value: "YOUR_BODY" },
	{ name: "Body shape", value: "BODY_SHAPE" },
];

export const arrShould = [
	{ img: do1, title: "Front-facing" },
	{ img: do2, title: "Standing pose" },
];

export const arrNotShould = [
	{ img: not1, title: "Many persons" },
	{ img: not2, title: "Side pose" },
	{ img: not3, title: "Sitting pose" },
];

export const arrShouldItem = [
	{ img: eItem_1, title: "Clean background" },
	{ img: eItem_2, title: "Front angle shoot" },
];

export const arrNotShouldItem = [
	{ img: notItem_1, title: "Colorful background" },
	{ img: notItem_2, title: "Model wears items" },
	{ img: notItem_3, title: "Two items" },
];

export const arrShouldHair = [
	{ img: do1, title: "Full head" },
	{ img: shouldHair_2, title: "Clear face" },
];

export const arrNotShouldHair = [
	{ img: not1, title: "Many persons" },
	{ img: notHair_2, title: "A part of hair" },
	{ img: notHair_3, title: "Small hair section" },
];

export const arrShouldAnimate = [
	{ img: notAnimate, title: "Front- facing & Clean background" },
];

export const arrNotShouldAnimate = [
	{ img: not1, title: "Many persons" },
	{ img: not2, title: "Side pose" },
	{ img: not3, title: "Sitting pose" },
	{ img: notHands, title: "Hands are hidden & Messy background" },
];

export const CATEGORY = [
	{
		value: "UPPER",
		name: "Upper body",
	},
	{
		value: "LOWER",
		name: "Lower body",
	},
	{
		value: "DRESSES",
		name: "Dress body",
	},
];

export const SIZE_BODY = [
	{
		value: "full-length",
		name: "Full length",
	},
	{
		value: "knee-length",
		name: "Knee length",
	},
	{
		value: "mid-thigh",
		name: "Mid thigh",
	},
];

export const SIZE_HAIR = [
	{
		value: "long-hair",
		name: "Long hair",
	},
	{
		value: "short-hair",
		name: "Short hair",
	},
	{
		value: "mid-hair",
		name: "Mid hair",
	},
];

export const DATA_BUTTON = [
	{ value: 1, name: EPricingPlan.MONTHLY },
	{ value: 12, name: EPricingPlan.ANNUAL },
];

export const arrMenu = [
	{
		icon: (color) => Icons.Home(color),
		text: "Homepage",
		link: APP_ROOT.DASHBOARD,
	},
	{
		icon: (color) => Icons.User(color),
		text: "Preset Model",
		link: APP_ROOT.MODELS,
	},
	{
		icon: (color) => Icons.Star(color),
		text: "Your Generation",
		link: APP_ROOT.YOUR_GENERATION,
	},
	{
		icon: (color) => Icons.Uploaded(color),
		text: "Your Asset",
		link: APP_ROOT.BODY_SHAPES,
	},
	{
		icon: (color) => Icons.Api(color),
		text: "Pricing",
		link: APP_ROOT.PLAN,
	},
	{
		icon: (color) => Icons.Transaction(color),
		text: "Transaction",
		link: APP_ROOT.TRANSACTION,
	},
];

export const arrTabStepTwoHair = [
	{ title: "Upload Photo", value: "UPLOAD" },
	{ title: "Hair Collection", value: "MODEL" },
];

export const arrTabStepTwoGenMe = [
	{ title: "Upload Photo", value: "UPLOAD" },
	{ title: "Desired Face", value: "MODEL" },
];

export const arrGallery = [
	{
		name: "Style Fit",
		value: TypeServiceName.OUTFIT,
	},
	{
		name: "Style Hair",
		value: TypeServiceName.HAIR_SWAP,
	},
	{
		name: "Gen Me",
		value: TypeServiceName.GEN_ME,
	},
];

export const LANGUAGES = [
	{
		img: enFlag,
		name: "English",
		value: "en",
	},
	{
		img: koFlag,
		name: "Korean",
		value: "ko",
	},
	{
		img: viFlag,
		name: "Vietnamese",
		value: "vi",
	},
];

export const isP2P = false;

export const privacyPolicyUrls = {
	en: "https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN.pdf",
	ko: "https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN-KO.pdf",
	vi: "https://stylegen.io/assets/PRIVACY-POLICY-STYLEGEN-VIE.pdf",
};

export const termsAndConditionsUrls = {
	en: "https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN.pdf",
	ko: "https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN-KO.pdf",
	vi: "https://stylegen.io/assets/TERMS-AND-CONDITIONS-STYLEGEN-VIE.pdf",
};

export const paymentPrivacyPolicyUrls = {
	en: "https://stylegen.io/assets/PAYMENT-STYLEGEN.pdf",
	ko: "https://stylegen.io/assets/PAYMENT-STYLEGEN.pdf",
	vi: "https://stylegen.io/assets/PAYMENT-STYLEGEN.pdf",
};

export const deliveryPolicyUrls = {
	en: "https://stylegen.io/assets/DELIVERY-STYLEGEN.pdf",
	ko: "https://stylegen.io/assets/DELIVERY-STYLEGEN.pdf",
	vi: "https://stylegen.io/assets/DELIVERY-STYLEGEN.pdf",
};

export const warrantyPolicyUrls = {
	en: "https://stylegen.io/assets/WARRANTY-POLICY-STYLEGEN.pdf",
	ko: "https://stylegen.io/assets/WARRANTY-POLICY-STYLEGEN.pdf",
	vi: "https://stylegen.io/assets/WARRANTY-POLICY-STYLEGEN.pdf",
};

export const genTransactionID = () => {
	const app_time = new Date().valueOf(); //moment().utc().unix()//1741233156653 // (moment().unix()) * 1000;
	const now = moment().format("YYMMDD");
	return `${now}_${app_time}`
}

export const ZALO_PAYMENT_PAYLOAD = {
	"app_id": +(process.env.REACT_APP_ZALO_APP_ID || 0),
	"bank_code": "",
	"embed_data": JSON.stringify({ "preferred_payment_method": ["zalopay_wallet"] }),
	"callback_url": `${process.env.REACT_APP_URL}`,
}