/** @format */

import React, { useCallback } from "react";
import { Box, Divider, useTheme } from "@mui/material";
import { AvatarCus, TextCus } from "components";
import { fontSize, mainColor } from "theme";
import { useLocation, useNavigate } from "react-router-dom";
import { useLayout, useUser } from "hooks";
import { EModelAuth, ThemeOption } from "types";
import { arrMenu, deliveryPolicyUrls, paymentPrivacyPolicyUrls, privacyPolicyUrls, termsAndConditionsUrls, warrantyPolicyUrls } from "utils";

const Sidebar = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const { user, isLogged, mode, languages } = useUser();
	const { setOpenModalAuth } = useLayout();

	const theme = useTheme() as ThemeOption;

	const renderMenu = useCallback(() => {
		return arrMenu.map((item, index) => (
			<Box key={item.text}>
				{index === 4 && (
					<Divider sx={{ bgcolor: theme.colorSchemes[mode]?.bgHover }} />
				)}
				<Box
					sx={{
						cursor: "pointer",
						transition: "all 0.15s ease-in-out",
						":hover": {
							bgcolor: "rgb(149 119 217 / 9%)",
						},
						marginTop: index === arrMenu.length - 2 ? "20px" : "0px",
					}}
					position={"relative"}
					component={"div"}
					display={"flex"}
					alignItems={"center"}
					height={"56px"}
					borderRadius={"12px"}
					bgcolor={
						location.pathname === item.link ? mainColor.bgButton : "transparent"
					}
					paddingLeft={"20px"}
					onClick={() => {
						if (!isLogged) return setOpenModalAuth(EModelAuth.LOGIN);
						navigate(item.link);
					}}>
					<Box
						display={"flex"}
						alignItems={"center"}
						justifyContent={"center"}
						sx={{
							transition: "all 0.25s ease-in-out",
						}}
						gap={"20px"}>
						{item.icon(theme.colorSchemes[mode]?.colorIcon)}
						<TextCus
							fontSize={fontSize.xs}
							fontWeight={"500"}
							color={theme.colorSchemes[mode]?.textMain}>
							{item.text}
						</TextCus>
					</Box>
				</Box>
			</Box>
		));
	}, [location.pathname, isLogged, mode]);

	return (
		<Box
			flexDirection={"column"}
			justifyContent={"space-between"}
			height={"100%"}
			sx={{
				display: {
					xs: "none",
					md: "flex",
				},
			}}>
			<Box display={"flex"} flexDirection={"column"} gap={"15px"}>
				<Box alignItems={"center"} gap={"15px"} display={"flex"} my={"20px"}>
					<AvatarCus />
					{user ? (
						<Box
							display={"flex"}
							flexDirection={"column"}
							width={"calc(100% - 55px)"}>
							<TextCus
								fontSize={fontSize.sm}
								color={theme.colorSchemes[mode]?.textMain}
								className="res_text"
								fontWeight={"600"}>
								{user?.userName}
							</TextCus>
							<TextCus
								fontSize={fontSize.xs}
								color={theme.colorSchemes[mode]?.textMain}
								className="res_text"
								fontWeight={"400"}>
								{user?.email}
							</TextCus>
						</Box>
					) : (
						<TextCus
							fontSize={fontSize.xs}
							color={theme.colorSchemes[mode]?.textMain}>
							Your name
						</TextCus>
					)}
				</Box>
				{renderMenu()}
			</Box>

			<Box
				paddingLeft={"20px"}
				display={"flex"}
				flexDirection={"column"}
				gap={"10px"}>
				{/* <MultiLanguage /> */}
				<Divider
					sx={{ my: "10px", bgcolor: theme.colorSchemes[mode]?.bgHover }}
				/>
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href={privacyPolicyUrls[languages.value] || privacyPolicyUrls.en}>
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textGray}
						fontWeight={"500"}>
						Privacy Policy
					</TextCus>
				</Box>
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href={
						termsAndConditionsUrls[languages.value] || termsAndConditionsUrls.en
					}>
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textGray}
						fontWeight={"500"}>
						Terms and Conditions
					</TextCus>
				</Box>
				{/* paymentPrivacyPolicyUrls */}
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href={
						paymentPrivacyPolicyUrls[languages.value] || paymentPrivacyPolicyUrls.en
					}>
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textGray}
						fontWeight={"500"}>
						Payment Privacy Policy
					</TextCus>
				</Box>
				{/* deliveryPolicyUrls */}
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href={
						deliveryPolicyUrls[languages.value] || deliveryPolicyUrls.en
					}>
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textGray}
						fontWeight={"500"}>
						Delivery Policy
					</TextCus>
				</Box>
				{/* warrantyPolicyUrls */}
				<Box
					component={"a"}
					sx={{ textDecoration: "none" }}
					target="blank"
					href={
						warrantyPolicyUrls[languages.value] || warrantyPolicyUrls.en
					}>
					<TextCus
						fontSize={"12px"}
						color={theme.colorSchemes[mode]?.textGray}
						fontWeight={"500"}>
						Warranty Policy
					</TextCus>
				</Box>
			</Box>
		</Box>
	);
};

export default Sidebar;
