/** @format */

import axios, {
	AxiosError,
	AxiosResponse,
	InternalAxiosRequestConfig,
} from "axios";
import { useKey } from "hooks";
// import { authService } from 'services';

import { KEY_CONTEXT } from "utils";
import { authService } from "./authService";

const config = {
	baseURL: process.env.REACT_APP_BASE_URI,
	headers: {
		Accept: "application/json",
		"Content-Type": "application/json",
	},
};

const axiosClient = axios.create(config);

axiosClient.interceptors.request.use(
	async (req: any) => {
		const { getKey } = useKey();
		const token = getKey(KEY_CONTEXT.AUTH_TOKEN);

		if (token) {
			req.headers.Authorization = `Bearer ${token}`;
		}

		return req;
	},
	(err: any) => Promise.reject(err),
);

axiosClient.interceptors.response.use(
	(response: AxiosResponse) => {
		return response;
	},
	async (err: AxiosError) => {

		const originalRequest = err.config as InternalAxiosRequestConfig & {
			_retry?: boolean;
		};
		console.log("🚀 ~ err:", err.response?.status)


		if ([401, 403].includes(err.response?.status) && !originalRequest._retry) {
			originalRequest._retry = true;

			const { getKey, setKeySite, logout403 } = useKey();

			const rfToken = getKey(KEY_CONTEXT.REFRESH_TOKEN);

			try {
				const newToken: any =
					(await authService.refreshToken(`${rfToken}`)) || "";
				console.log("🚀 ~ newToken:", newToken)

				const { access, refresh } = newToken.data;
				console.log("🚀 ~ access, refresh:", access, refresh)
				if (access && refresh) {
					setKeySite({ authToken: access, refreshToken: refresh });
					// Set new token in default headers
					axiosClient.defaults.headers.common[
						"Authorization"
					] = `Bearer ${access}`;
					return axiosClient(originalRequest);
				}
				await logout403();
			} catch (refreshError) {
				console.log("🚀 ~ refreshError:", refreshError)
				return Promise.reject(refreshError);
			}
		}
		return Promise.reject(((err || {}).response || {}).data);
	},
);

export default axiosClient;
