/** @format */

import React, { createContext, useContext, useEffect } from 'react';

import { useKey, useUser } from 'hooks';
import { AuthContextType } from 'types';
import { KEY_CONTEXT } from 'utils';
import { userService } from 'services';

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const { getKey, removeKey, removeKeySite } = useKey();
  const { isLogged, setIsLogged } = useUser();

  const login = () => setIsLogged(true);

  useEffect(() => {
    const access_token = getKey(KEY_CONTEXT.AUTH_TOKEN);
    if (access_token) {
      setIsLogged(true);
    }
  }, []);

  const logout = async () => {
    console.log("🚀 ~ logout ~ logout: useAuth")

    removeKey('deviceId');
    setIsLogged(false);
    removeKeySite();
    try {
      await userService.logout();
    } catch (error) {
      console.log("🚀 ~ logout ~ error:", error)

    }
  };

  return (
    <AuthContext.Provider value={{ isLogged, login, logout }}>{children}</AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
