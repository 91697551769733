/** @format */

import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
	Box,
	CircularProgress,
	Fade,
	IconButton,
	Modal,
	useTheme,
} from "@mui/material";
import { TextCus } from "components/TextCus";
import { fontSize, mainColor } from "theme";
import { CloseOutlined } from "@mui/icons-material";
import useClickOutside from "hooks/useClickOutside";
import { fit, Icons, kc2 } from "assets";
import { IFinalOutfitResult } from "types/outfit";
import {
	// useAnimateStore,
	useDownloadImage,
	// useGenMeStore,
	useOutfitStore,
	useUser,
} from "hooks";
import { APP_ROOT } from "utils/routes";
import { useTranslation } from "react-i18next";
import { ThemeOption, TypeServiceName } from "types";
import SnackbarUtils from "utils/SnackbarUtils";

interface IModalResultBMI {
	open: boolean;
	onClose: () => void;
	goBack?: () => void;
	dataResult: IFinalOutfitResult;
}

const ModalResultBMI = ({ open, onClose, dataResult }: IModalResultBMI) => {
	const dropdownRef = useRef<HTMLDivElement>(null);
	useClickOutside(dropdownRef, () => setIsOpen(false));
	const [isOpen, setIsOpen] = useState(false);
	const { user } = useUser();
	const navigate = useNavigate();
	const { handleDownLoadImg, isLoadingDlImg } = useDownloadImage();
	const { t } = useTranslation();

	const [previewUrl, setPreviewUrl] = useState<string | null>();
	const [isPreviewVisible, setIsPreviewVisible] = useState(false);

	const {
		setSelectedFileModel,
		setModelImage,
		setSelectedFileGallery,
		setCurrentStep,
	} = useOutfitStore();

	// const {
	// 	setSelectedFileModel: setSelectedFileModelGenMe,
	// 	setModelImage: setModelImageGenMe,
	// 	setSelectedFileGallery: setSelectedFileGalleryGenMe,
	// 	setCurrentStep: setCurrentStepGenMe,
	// } = useGenMeStore();

	// const {
	// 	setSelectedFileModel: setSelectedFileModelAnimate,
	// 	setModelImage: setModelImageAnimate,
	// 	setSelectedFileGallery: setSelectedFileGalleryAnimate,
	// 	setCurrentStep: setCurrentStepAnimate,
	// } = useAnimateStore();

	useEffect(() => {
		if (dataResult?.url) {
			typeof dataResult?.url === "string" && setPreviewUrl(dataResult?.url);
		}
	}, [dataResult?.url]);

	useEffect(() => {
		let timer: NodeJS.Timeout;
		if (previewUrl) {
			setIsPreviewVisible(false);
			timer = setTimeout(() => {
				setIsPreviewVisible(true);
			}, 300);
		}
		return () => clearTimeout(timer);
	}, [previewUrl]);

	const handleChooseModel = () => {
		setSelectedFileModel(dataResult.galleryId as string);
		setModelImage(dataResult.url as string);
		setSelectedFileGallery(null);
		setCurrentStep(1);
		navigate(APP_ROOT.STYLE_FIT);
		onClose();
	};

	const navigateGenMe = () => {
		return SnackbarUtils.warning("Feature under maintenance.");
		// setSelectedFileModelGenMe(dataResult.galleryId as string);
		// setModelImageGenMe(dataResult.url as string);
		// setSelectedFileGalleryGenMe(null);
		// setCurrentStepGenMe(1);
		// navigate(APP_ROOT.GEN_ME);
		// onClose();
	};

	const navigateAnimate = () => {
		return SnackbarUtils.warning("Feature under maintenance.");
		// setSelectedFileModelAnimate(dataResult.galleryId as string);
		// setModelImageAnimate(dataResult.url as string);
		// setSelectedFileGalleryAnimate(null);
		// setCurrentStepAnimate(1);
		// navigate(APP_ROOT.ANIMATE);
		// onClose();
	};

	const arrBtn = [
		{
			title: "Style Fit",
			icon: Icons.Fit(),
			action: () => handleChooseModel(),
			desc: "Change clothes",
		},
		// {
		// 	title: "Style Hair",
		// 	icon: Icons.Hair(),
		// 	action: () => navigateStyleHair(),
		// 	desc: "Change hairstyle",
		// },
		{
			title: "Gen Me",
			icon: Icons.Me(),
			action: () => navigateGenMe(),
			desc: "Face Swap",
		},
		{
			title: "Animate Anyone",
			icon: Icons.Animate(),
			action: () => navigateAnimate(),
			desc: "Animate me",
		},
	];

	const renderButton = useCallback(() => {
		return (
			<Box
				display={"flex"}
				alignItems={"center"}
				gap={"15px"}
				justifyContent={"space-between"}>
				{arrBtn.map((item, index) => (
					<Box
						onClick={dataResult && item.action}
						sx={{
							cursor: "pointer",
							transition: "all 0.25s ease-in-out",
							background: mainColor.bgLinear,
							"&:hover": {
								".box_desc": {
									top: "-30px",
									opacity: 1,
								},
							},
						}}
						key={item.title}
						borderRadius={"12px"}
						flex={1}
						display={"flex"}
						alignItems={"center"}
						gap={"10px"}
						justifyContent={"center"}
						height={"44px"}
						position={"relative"}>
						{index === 0 ? <img src={fit} alt="fit" /> : item.icon}
						<TextCus
							color={"#ffffff"}
							fontWeight={"600"}
							fontSize={fontSize.xs}>
							{item.title}
						</TextCus>
						<Box
							className="box_desc"
							position={"absolute"}
							height={"20px"}
							bgcolor={"#5553BA"}
							width={"110px"}
							borderRadius={"12px"}
							color={"white"}
							fontSize={"10px"}
							fontWeight={"400"}
							textAlign={"center"}
							lineHeight={"20px"}
							sx={{ transition: "all 0.25s ease-in-out", opacity: 0 }}
							top={"-10px"}>
							{item.desc}
						</Box>
					</Box>
				))}
			</Box>
		);
	}, [dataResult?.url]);

	const { mode } = useUser();
	const theme = useTheme() as ThemeOption;

	return (
		<Modal
			keepMounted
			open={open}
			closeAfterTransition
			sx={{
				backgroundColor: "#000000087",
				zIndex: 9999,
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
			}}
			onClose={onClose}
			aria-labelledby="keep-mounted-modal-title"
			aria-describedby="keep-mounted-modal-description">
			<Fade in={open} timeout={500}>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"space-between"}
					bgcolor={theme.colorSchemes[mode]?.bgModal}
					flexDirection={"column"}
					sx={{
						backdropFilter: "blur(15px)",
						maxWidth: "680px",
						width: {
							xs: "90vw",
						},
					}}
					height={"80vh"}
					maxHeight={"905px"}
					overflow={"hidden"}
					borderRadius={"12px"}>
					<Box
						width={"100%"}
						display={"flex"}
						alignItems={"center"}
						padding={"10px 20px"}
						bgcolor={mode === "dark" ? "rgba(112, 109, 218, 0.7)" : "#E8E7FE"}
						justifyContent={"space-between"}>
						<Box />
						<TextCus
							color={theme.colorSchemes[mode]?.textMain}
							fontWeight={"700"}
							fontSize={"16px"}>
							Your Body Shape
						</TextCus>
						<IconButton onClick={onClose} sx={{ marginRight: "-10px" }}>
							<CloseOutlined
								sx={{ color: theme.colorSchemes[mode]?.textMain }}
							/>
						</IconButton>
					</Box>
					<Box
						padding={"20px"}
						display={"flex"}
						flexDirection={"column"}
						alignItems={"center"}
						justifyContent={"space-between"}
						gap={"20px"}
						width={"100%"}
						height={"calc(100% - 100px)"}
						// sx={{ overflowY: "scroll" }}
						flex={1}>
						<Box
							width={"100%"}
							height={"32px"}
							borderRadius={"12px"}
							display={"flex"}
							alignItems={"center"}
							gap={"5px"}
							fontSize={fontSize.xs}
							fontWeight={"400"}
							padding={"0 15px"}
							margin={"10px 0"}
							color={"rgba(52, 199, 89, 1)"}
							bgcolor={"rgba(52, 199, 89, 0.15)"}>
							{Icons.CheckBoxSuccess()} {t("Your body shape has been saved to")}
							<Box
								component={"span"}
								onClick={() => {
									navigate(APP_ROOT.BODY_SHAPES);
									onClose();
								}}
								sx={{ textDecoration: "underline", cursor: "pointer" }}>
								{t("Your Asset")}
							</Box>
						</Box>
						<Box
							display={"flex"}
							alignItems={"flex-start"}
							flex={1}
							width={"100%"}
							height={"calc(100% - 210px)"}
							gap={"15px"}
							justifyContent={"center"}>
							<Box
								bgcolor={"#D0D2D1"}
								position={"relative"}
								borderRadius={"12px"}
								height={"100%"}
								display={"flex"}
								justifyContent={"center"}
								width={"100%"}>
								<img
									src={previewUrl + ""}
									alt="Preview"
									style={{
										display: "block",
										height: "100%",
										maxWidth: "100%",
										opacity: isPreviewVisible ? 1 : 0,
										transition: "opacity 0.5s ease-in-out",
										borderRadius: "10px",
										objectFit: "cover",
									}}
								/>
								{user?.plan !== "FREE" ? (
									<Box
										ref={dropdownRef}
										position={"absolute"}
										top={"10px"}
										right={"10px"}
										onClick={() =>
											handleDownLoadImg(
												previewUrl,
												"Body-shape.png",
												dataResult.galleryId,
												TypeServiceName.BODY_SHAPE,
											)
										}
										bgcolor={mainColor.white}
										height={"36px"}
										width={"36px"}
										borderRadius={"10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										sx={{
											cursor: "pointer",
										}}>
										{isLoadingDlImg ? (
											<CircularProgress
												sx={{
													height: "30px !important",
													width: "30px !important",
												}}
											/>
										) : (
											<Icons.Download />
										)}
									</Box>
								) : (
									<Box
										ref={dropdownRef}
										position={"absolute"}
										top={"10px"}
										right={"10px"}
										onClick={() => setIsOpen(true)}
										bgcolor={mainColor.white}
										height={"36px"}
										width={"36px"}
										borderRadius={"10px"}
										display={"flex"}
										alignItems={"center"}
										justifyContent={"center"}
										sx={{
											cursor: "pointer",
										}}>
										<Icons.Download />
										{/* Dropdown download */}
										<Box
											bgcolor={mainColor.white}
											borderRadius={"12px"}
											position={"absolute"}
											top={isOpen ? "43px" : "20px"}
											sx={{
												opacity: isOpen ? 1 : 0,
												transition: "all 0.25s ease-in-out",
											}}
											width={"249px"}
											padding={"10px"}
											right={0}>
											<Box
												onClick={() =>
													handleDownLoadImg(
														dataResult?.imgDownload,
														"Body-shape.png",
														dataResult.galleryId,
														TypeServiceName.BODY_SHAPE,
													)
												}
												display={"flex"}
												padding={"10px 0"}
												border={"1px solid #B2B2B2"}
												borderRadius={"10px"}
												sx={{ cursor: "pointer", textDecoration: "none" }}
												gap={"3px"}
												flexDirection={"column"}
												alignItems={"center"}
												justifyContent={"center"}>
												<TextCus
													fontSize={fontSize.xs}
													fontWeight={"600"}
													color={mainColor.textMain}>
													Free download
												</TextCus>
												<TextCus
													fontSize={"12px"}
													fontWeight={"400"}
													color={mainColor.textGray}>
													Included the watermark
												</TextCus>
											</Box>
											<Box
												mt={"10px"}
												display={"flex"}
												padding={"10px 0"}
												sx={{ cursor: "pointer" }}
												borderRadius={"10px"}
												component={"div"}
												onClick={() => {
													navigate(APP_ROOT.PLAN);
													onClose();
												}}
												flexDirection={"column"}
												gap={"3px"}
												bgcolor={"#FFCB00"}
												alignItems={"center"}
												justifyContent={"center"}>
												<Box display={"flex"} alignItems={"center"} gap={"5px"}>
													<img width={20} src={kc2} alt="kc" />
													<TextCus
														fontSize={fontSize.xs}
														fontWeight={"600"}
														color={mainColor.textMain}>
														Premium download
													</TextCus>
												</Box>
												<TextCus
													fontSize={"12px"}
													fontWeight={"400"}
													color={mainColor.textGray}>
													Without the watermark
												</TextCus>
											</Box>
										</Box>
										{/* Dropdown download */}
									</Box>
								)}
							</Box>
						</Box>

						<Box
							display={"flex"}
							height={"135px"}
							flexDirection={"column"}
							justifyContent={"space-between"}
							borderRadius={"12px"}
							gap={"5px"}
							width={"100%"}
							padding={"15px"}
							border={"1px solid #EEEEEE"}>
							<Box display={"flex"} alignItems={"center"} gap={"10px"}>
								<TextCus
									color={theme.colorSchemes[mode]?.textMain}
									fontSize={fontSize.xs}
									fontWeight={"600"}>
									Apply your body shape for:
								</TextCus>
							</Box>
							<TextCus
								color={theme.colorSchemes[mode]?.textMain}
								fontSize={"12px"}
								fontWeight={"400"}>
								Use your body shape with different features of StyleGen now.
							</TextCus>
							{renderButton()}
						</Box>
					</Box>
				</Box>
			</Fade>
		</Modal>
	);
};

export default ModalResultBMI;
