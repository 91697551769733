import React, { useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { formatVND, QueryKeys, sleep } from "utils";
import { paymentService } from "services/paymentService";
import { fontSize, mainColor } from "theme/default";
// import { PayPalButtons } from "@paypal/react-paypal-js";
import { useLayout, useUser } from "hooks";
// import {
// 	CreateOrderActions,
// 	OnApproveData,
// 	OnApproveActions,
// 	CreateOrderData,
// } from "@paypal/paypal-js";
import { STATUS_PAYMENT, TDetailBill } from "types/payment";
import {
	ButtonCus,
	ModalPaymentFail,
	ModalPaymentSuccess,
	TextCus,
} from "components";
import { BannerInfo } from "../components";
import { Icons, zalopay } from "assets";
import { EModelAuth } from "types";
import { userService } from "services";
import i18n from "i18n";

const PlanInfoPayment = () => {
	const { user } = useUser()
	const { setOpenModalAuth } = useLayout();
	const [searchParams] = useSearchParams();
	const id = searchParams.get("id");
	const quantity = searchParams.get("quantity") || 1;
	// const { getProfile } = useProfile();
	// const prevRef = useRef({ orderID: "" }).current;

	const { data, isLoading } = useQuery({
		queryKey: [QueryKeys.GET_DETAIL_PLAN, id, quantity],
		queryFn: () => paymentService.detailPlan(id + ""),
	});
	const detailPlan = data?.data;
	console.log("🚀 ~ PlanInfoPayment ~ detailPlan:", detailPlan)
	const [statusPayment, setStatusPayment] = useState<
		STATUS_PAYMENT.ANNUAL | STATUS_PAYMENT.SUCCESS | STATUS_PAYMENT.FAIL
	>(STATUS_PAYMENT.ANNUAL);
	const [detailBill, setDetailBill] = useState<null | TDetailBill>(null);
	const { isLogged } = useUser();
	const [paymentZaloLoading, setPaymentZaloLoading] = useState(false);

	useEffect(() => {
		if (!isLogged) {
			setOpenModalAuth(EModelAuth.LOGIN);
		}
	}, [isLogged]);

	// const createOrder = async (
	// 	data: CreateOrderData,
	// 	actions: CreateOrderActions,
	// ) => {
	// 	console.log("========createOrder==========>", data, actions);
	// 	try {
	// 		const res = await paymentService.orderPaypal({
	// 			reference_id: id + "",
	// 			qty: quantity + "",
	// 		});
	// 		console.log("Res Create Order", res);
	// 		prevRef.orderID = res?.data.id;

	// 		return res?.data.id;
	// 	} catch (error) {
	// 		console.log("error createOrder ===>", error);
	// 		setStatusPayment(STATUS_PAYMENT.FAIL);
	// 	}
	// };

	// const approve = async (data: OnApproveData, actions: OnApproveActions) => {
	// 	console.log("=========approve=========>", data, actions);
	// 	try {
	// 		const res = await paymentService.approvePaypal({
	// 			orderID: prevRef.orderID,
	// 			quantity: quantity + "",
	// 		});
	// 		if (res?.data) {
	// 			if (res.data?.error) {
	// 				return setStatusPayment(STATUS_PAYMENT.FAIL);
	// 			}
	// 			getProfile();
	// 			setStatusPayment(STATUS_PAYMENT.SUCCESS);
	// 			setDetailBill(res.data);
	// 		}
	// 	} catch (error) {
	// 		console.log("error approve ===>", error);
	// 		setStatusPayment(STATUS_PAYMENT.FAIL);
	// 	}
	// };

	// const onTracking = async (type: "CHECKOUT_PLAN" | "CANCEL_PLAN") => {
	// 	try {
	// 		await userService.trackingPlan({
	// 			type,
	// 			packageName: detailPlan.packageName,
	// 			quantity: quantity + "",
	// 		});
	// 	} catch (error) {
	// 		console.log("error approve ===>", error);
	// 	}
	// };

	const onCreateZaloPayment = async () => {
		if (paymentZaloLoading) return;
		setPaymentZaloLoading(true);
		// console.log("🚀 ~ onCreateZaloPayment ~ user.email:", user.email)
		const order = await userService.zaloCreatePayment(
			{
				item: [{ price: detailPlan.price, quantity: +quantity !== 1 ? 12 : 1, planId: detailPlan._id, uid: user.id }],
				email: user.email.substring(0, 50),
				planId: detailPlan._id,
				quantity,
				lang: i18n.language,
			}
		);
		console.log("🚀 ~ onCreateZaloPayment ~ quantity:", quantity)
		// console.log("🚀 ~ onCreateZaloPayment ~ rs:", order)
		if (order.order_url) {
			trackingZalo(order.app_trans_id)

			const link = window.document.createElement('a');
			link.setAttribute("target", "_blank")
			link.href = order.order_url
			link.click();
		}
	}

	const trackingZalo = async (app_trans_id) => {
		console.log("🚀 ~ trackingZalo ~ app_trans_id:", app_trans_id)
		let tracking = true;
		let tracking_count = 0;
		while (tracking && tracking_count < 50) {
			await sleep(5000)
			const rs = await userService.zaloTrackingOrder(app_trans_id)
			// console.log("🚀 ~ trackingZalo ~ rs:", rs)
			if (rs.return_code < 3) {
				tracking = false
				setPaymentZaloLoading(false);
				if (rs.return_code === 1) {
					const credit = +(detailPlan.description[0].split(" ")[0]);
					if (isNaN(credit)) {
						setStatusPayment(STATUS_PAYMENT.FAIL);
						return;
					}
					// setStatusPayment(STATUS_PAYMENT.SUCCESS);
					// setDetailBill({
					// 	totalCredit: credit,
					// 	invoiceId: app_trans_id,
					// 	startDate: moment(rs.server_time).format("DD/MM/YYYY HH:mm:ss"),
					// 	amountSpent: rs.amount
					// });
					return;
				}

				setStatusPayment(STATUS_PAYMENT.FAIL);

			}
			tracking_count > 3 && setPaymentZaloLoading(false);
			tracking_count++;

		}
	}

	if (!detailPlan || !quantity || !id)
		return (
			<>
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					height={"100vh"}>
					<CircularProgress />
				</Box>
			</>
		);

	const totalPrice = Math.round(
		detailPlan.priceSale *
		+quantity *
		(+quantity === 1 ? 1 : 0.85),
	);
	console.log("🚀 ~ PlanInfoPayment ~ quantity:", quantity)

	return (
		<Box
			display={"flex"}
			flexDirection={"column"}
			justifyContent={"center"}
			alignItems={"center"}
			gap={"20px"}
			marginBottom={"20px"}>
			<BannerInfo />
			{isLoading ? (
				<Box
					display={"flex"}
					alignItems={"center"}
					justifyContent={"center"}
					height={"100px"}>
					<CircularProgress />
				</Box>
			) : (
				<Box
					zIndex={999}
					padding={"2px"}
					maxWidth={"600px"}
					width={"97%"}
					borderRadius={"20px"}
					sx={{
						marginTop: { xl: "-150px", xs: "-50px" },
						background: mainColor.bgLinear,
					}}>
					<Box
						bgcolor={mainColor.white}
						borderRadius={"20px"}
						padding={"20px"}
						flexDirection={"column"}
						display={"flex"}
						gap={"15px"}>
						<Box
							display={"flex"}
							flexDirection={"column"}
							justifyContent={"center"}
							alignItems={"center"}
							marginBottom={"10px"}
							color={mainColor.textMain}>
							<TextCus
								className="text_linear"
								fontSize={fontSize.xl}
								fontWeight={"700"}
								fontStyle={"italic"}>
								PLAN SUMMARY
							</TextCus>
						</Box>

						<Box
							justifyContent={"start"}
							gap={"15px"}
							alignItems={"center"}
							flexWrap={"wrap"}
							display={"flex"}>
							<Box display={"flex"} alignItems={"flex-end"} gap={"2px"}>
								<TextCus
									color={mainColor.secondary}
									fontSize={fontSize.xl}
									fontWeight={"700"}>
									{formatVND(totalPrice * 25000)}
								</TextCus>
								<TextCus color={mainColor.textGray} fontSize={fontSize.sm}>
									{+quantity === 1 ? "/month" : "/year"}
								</TextCus>
							</Box>
						</Box>

						<Box display={"flex"} flexDirection={"column"}>
							<Box
								display={"flex"}
								alignItems={"center"}
								borderBottom={"1px solid #ccc"}
								paddingBottom={"10px"}
								marginBottom={"10px"}
								justifyContent={"space-between"}>
								<TextCus
									color={mainColor.textGray}
									useI18n
									fontSize={fontSize.sm}>
									Tax
								</TextCus>
								<TextCus
									color={mainColor.textMain}
									fontSize={fontSize.sm}
									fontWeight={"700"}>
									{formatVND(0.00)}
								</TextCus>
							</Box>
							<Box
								display={"flex"}
								alignItems={"center"}
								justifyContent={"space-between"}>
								<TextCus
									color={mainColor.textGray}
									useI18n
									fontSize={fontSize.md}>
									Total due
								</TextCus>
								<TextCus
									color={mainColor.textMain}
									fontSize={fontSize.md}
									fontWeight={"700"}>
									{formatVND(totalPrice * 25000)}
								</TextCus>
							</Box>
						</Box>

						<Box
							component={"ul"}
							marginTop={"10px"}
							sx={{ listStyleType: "none" }}
							display={"flex"}
							gap={"5px"}
							flexDirection={"column"}>
							<TextCus
								color={mainColor.secondary}
								fontSize={fontSize.md}
								fontWeight={"700"}>
								{detailPlan.packageName}
							</TextCus>
							<TextCus color={mainColor.textGray} fontSize={fontSize.xs}>
								{detailPlan.subTitle}
							</TextCus>
							{detailPlan?.description?.map((item, index) => {
								return (
									<Box
										component={"li"}
										display={"flex"}
										mt={"5px"}
										alignItems={"center"}
										gap={"15px"}
										color={"#002F74"}
										fontSize={"14px"}
										fontWeight={"400"}
										textTransform={"capitalize"}
										key={index}>
										{Icons.Tick()}
										{item.toLocaleLowerCase()}
									</Box>
								);
							})}
						</Box>

						<TextCus
							color={mainColor.textGray}
							fontSize={"13px"}
							fontStyle={"italic"}
							maxWidth={"1000px"}>
							* By providing your payment information and clicking “Confirm
							Purchase/Upgrade Plan”, you agree you will be charged the amount
							listed above and your subscription will automatically renew untill
							you cancel (price subject to change). Cancel anytime via Account
							Settings or Customer Support. You also agree to the Terms of
							Service and Privacy Policy.
						</TextCus>

						<Box display={"flex"} flexDirection={"column"} gap={"20px"}>
							<TextCus
								color={mainColor.textMain}
								fontStyle={"italic"}
								fontSize={fontSize.sm}>
								* Payment Method:
							</TextCus>
							{!isLogged ? (
								<ButtonCus
									sx={{ height: "60px !important" }}
									onClick={() => setOpenModalAuth(EModelAuth.LOGIN)}>
									Login
								</ButtonCus>
							) : (
								<Box width={"100%"} margin={"0 auto"}>
									<Box alignItems={'center'} flexDirection={'row'} mb={2} paddingX={10} borderRadius={2} onClick={() => onCreateZaloPayment()} display={'flex'} justifyContent={'center'} border={'1px solid #1da1f2'}>
										{paymentZaloLoading && <CircularProgress size={20} />} <img src={zalopay} style={{ height: 45, padding: 10 }} />
									</Box>
									{/* <PayPalButtons
										createOrder={createOrder}
										onApprove={approve}
										onCancel={() => onTracking("CANCEL_PLAN")}
										onInit={() => onTracking("CHECKOUT_PLAN")}
									/> */}
								</Box>
							)}
						</Box>
					</Box>
				</Box>
			)}

			{/* {paymentZalo && <ModalZaloMethod
				onClose={() => setPaymentZalo(false)}
				onConfirm={(v) => {
					onCreateZaloPayment()
					setPaymentZalo(false)
				}}
			/>} */}

			{/* ======== MODALS STATUS ========= */}
			<ModalPaymentSuccess
				open={statusPayment === STATUS_PAYMENT.SUCCESS}
				onClose={() => {
					setDetailBill(null);
					setStatusPayment(STATUS_PAYMENT.ANNUAL);
				}}
				info={detailBill}
			/>
			<ModalPaymentFail
				open={statusPayment === STATUS_PAYMENT.FAIL}
				onClose={() => {
					setStatusPayment(STATUS_PAYMENT.ANNUAL);
				}}
			/>
			{/* ======== END MODALS STATUS ========= */}
		</Box>
	);
};

export default PlanInfoPayment;
